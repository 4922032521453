import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiCoffeeTogo } from "@react-icons/all-files/bi/BiCoffeeTogo";
import { useInView } from "react-intersection-observer";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";
const smooth = "transition-all ease-in-out duration-300";

const query = graphql`
  {
    allContentfulBanner(
      sort: { order: ASC, fields: bannerImage___createdAt }
      filter: { secondBanner: { eq: true } }
    ) {
      nodes {
        id
        title
        bannerImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const WelcomePanel = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const {
    allContentfulBanner: { nodes: posts },
  } = useStaticQuery(query);
  return (
    <div
      ref={ref}
      className={`transition-all ease-in-out duration-[1200ms] ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[200px]"
      } z-0 bg-white relative w-full h-full flex flex-col justify-start items-center mt-10 md:mt-0  pt-3 md:pt-20 pb-16 md:pb-24 shadow-xl shadow-stone-900/6`}
    >
      <div className="relative z-10 flex flex-col md:flex-row w-5/6 md:w-[96%] lg:w-4/5 md:h-[480px] bg-yellow-510 rounded-2xl drop-shadow-md shadow-md shadow-stone-500/20">
        {/* text */}
        <div className="h-full md:w-[45%] lg:w-[38%] flex flex-col justify-center items-center">
          <div className="flex flex-col justify-start items-start h-[40%] w-full pt-8 md:pt-10 px-8 md:px-10 brightness-105 leading-[2.5rem] lg:leading-[3rem] text-3xl md:text-[3.8vw] lg:text-[2.7vw] 2xl:text-[2.7rem]">
            <span className="flex justify-start items-center font-runegifter text-yellow-710">
              <BiCoffeeTogo className="text-yellow-710 mr-3" /> Add a burst of
            </span>
            <span className="font-runegifter text-yellow-710">
              warmness to start
            </span>
            <span className="font-runegifter text-yellow-710">your day</span>
          </div>
          <div className="h-[60%] pt-8 md:pt-0 lg:pt-3 px-8 md:px-10 flex flex-col justify-start  items-center">
            <div className="font-lufga300 text-base pb-4 md:pb-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard
            </div>
            <div className="font-lufga300 text-base pb-16 md:pb-0">
              When an unknown printer took a galley of type and scrambled it to
              make a type specimen book ....
            </div>
          </div>
        </div>

        {/* image */}
        <div className="h-full md:w-[55%] lg:w-[62%] bg-white rounded-b-2xl md:rounded-r-2xl group">
          <Swiper
            autoplay={{
              delay: 10000,
            }}
            navigation={true}
            pagination={true}
            spaceBetween={20}
            modules={[Autoplay, Navigation, Pagination]}
            className="customized-swiper swiper-small rounded-b-2xl md:rounded-r-2xl"
          >
            {posts.map((post) => {
              const { id, title, bannerImage } = post;
              const pathToImage = getImage(bannerImage);

              return (
                <SwiperSlide key={id}>
                  <GatsbyImage
                    image={pathToImage}
                    alt={title}
                    className={`${smooth} image-swiper h-[300px] md:h-[600px] brightness-[95%] group-hover:brightness-100`}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* button */}
      <div className="absolute bottom-[40%] md:bottom-[50px] md:left-[16%] z-20 w-[90%] md:w-[450px] h-[80px] md:h-[90px] bg-stone-50 rounded-2xl drop-shadow-md shadow-md flex justify-center items-center">
        <div className="flex justify-center md:justify-start items-center ml-4 md:ml-8 font-lufga300 text-base sm:text-lg md:text-xl w-[55%] cursor-default">
          Don't want to wait?
        </div>

        <div className="h-[30px] w-[2px] bg-stone-300" />

        <button
          className={`${hover} hover:bg-blue-710 flex justify-center items-center mx-4 md:mx-6 font-lufga400 text-base md:text-xl w-[45%] h-[60%] rounded-xl italic bg-stone-900 text-white`}
        >
          Order Now
        </button>
      </div>
    </div>
  );
};

export default WelcomePanel;
