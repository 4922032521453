import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import LandingTextTop from "../components/LandingTextTop";
import LandingCarousel from "../components/LandingCarousel";
import WelcomePanel from "../components/WelcomePanel";
import Info01 from "../components/Info01";
import Info02 from "../components/Info02";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SpecialMenu01 from "../components/SpecialMenu01";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { HiArrowCircleUp } from "@react-icons/all-files/hi/HiArrowCircleUp";
import { IoCall } from "@react-icons/all-files/io5/IoCall";
import ButtonUp from "../utilities/ButtomUp";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";

const IndexPage = () => {
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });
    return () => scroll.destroy();
  });

  return (
    <div
      data-scroll-container
      className="no-scrollbar relative overflow-hidden no-scrollbar flex flex-col justify-start items-center bg-gradient-to-b from-white to-stone-100 "
    >
      <div id="top2" />
      <div
        id="container"
        data-scroll-section
        className="relative w-screen 2xl:w-new flex flex-col justify-center items-center"
      >
        <div
          id="top"
          data-scroll
          data-scroll-speed="-1"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="lg:w-full lg:h-full flex justify-center items-center "
        >
          <Navbar link={"#contact"} />
          <a
            href={`#contact`}
            data-scroll-to
            className={`${hover} z-50 absolute top-12 right-[7.2rem] bg-stone-800 hover:bg-blue-710 hover:shadow-lg rounded-full h-16 w-16 mx-5 drop-shadow-md shadow-md hidden lg:grid place-content-center`}
          >
            <IoCall className="text-white text-[1.8rem]" />
          </a>
        </div>

        <div className="mt-8 md:mt-12" />
        <LandingTextTop />

        <div
          data-scroll
          data-scroll-speed="1"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <LandingCarousel />
        </div>

        <WelcomePanel />

        <Info01 />
        <SpecialMenu01 />

        <Info02 />

        <div
          id="contact"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="bottom"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <Contact />
        </div>

        <div
          id="top"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="bottom"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <Footer />
          <a
            href={`#top`}
            data-scroll-to
            className="absolute z-40 bottom-8 right-6 lg:right-16"
          >
            <button
              aria-label="up"
              className={`focus:outline-none z-50 bg-white h-12 w-12 md:h-14 md:w-14 rounded-full shadow-lg text-xl md:text-2xl text-green-110 hover:text-blue-710 hidden lg:flex justify-center items-center`}
            >
              <HiArrowCircleUp />
            </button>
          </a>
        </div>

        <div className="flex lg:hidden">
          <ButtonUp link={"#top2"} />
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
