import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";

const query = graphql`
  {
    allContentfulBanner(
      sort: { order: ASC, fields: bannerImage___createdAt }
      filter: { mainBanner: { eq: true } }
    ) {
      nodes {
        id
        title
        bannerImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const LandingCarousel = () => {
  const {
    allContentfulBanner: { nodes: posts },
  } = useStaticQuery(query);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`transition-all ease-in-out duration-[1200ms] ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
      } w-full h-full`}
    >
      <Swiper
        autoplay={{
          delay: 10000,
        }}
        navigation={true}
        pagination={true}
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={30}
        className={`customized-swiper 2xl:rounded-t-md`}
      >
        {posts.map((post) => {
          const { id, title, bannerImage } = post;
          const pathToImage = getImage(bannerImage);

          return (
            <SwiperSlide key={id}>
              <GatsbyImage
                image={pathToImage}
                alt={title}
                className={`image-swiper h-[300px] md:h-[600px]`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default LandingCarousel;
