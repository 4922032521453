import React from "react";
import { useInView } from "react-intersection-observer";

const Info02 = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className="relative z-20 flex flex-col bg-white justify-start items-center w-full md:pt-6 shadow-xl shadow-stone-900/6 2xl:rounded-b-md "
    >
      <div className="flex md:hidden w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl" />
      <div className="md:w-[96%] lg:w-4/5 mt-12 md:mt-16 flex flex-col justify-center items-center ">
        {/* item1 */}
        <div
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "opacity-100 translate-x-0 "
              : "opacity-0 translate-x-[200px]"
          } md:h-80 w-full mb-10 md:mb-16 flex flex-col md:flex-row justify-center items-center`}
        >
          <div
            className={`h-80 w-[80%] md:w-[48%] bg-slate-200 rounded-xl shadow-md drop-shadow-lg`}
          ></div>
          <div
            className={`h-full w-[80%] md:w-[52%] md:ml-8 flex flex-col justify-start items-center md:items-start`}
          >
            <div className="font-runegifter text-3xl md:text-4xl text-yellow-710 brightness-110 mt-6 mb-2 md:mb-6 ">
              Fusce et tortor elit
            </div>
            <div className="font-lufga300 text-base md:text-lg leading-7 text-center md:text-left">
              Duis eu lacinia metus. Aenean pulvinar libero sit amet magna
              interdum ultricies. Nunc mauris ex, tristique id sem eget,
              eleifend pulvinar dolor. Donec at libero metus. Suspendisse nisl
              libero, tempus nec ipsum et, scelerisque fringilla urna. Sed
              hendrerit diam odio, vel condimentum turpis viverra convallis.
            </div>
          </div>
        </div>
        {/* item2 */}
        <div
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "opacity-100 -translate-x-0"
              : "opacity-0 -translate-x-[200px]"
          } md:h-80 w-full mb-8 md:mb-16 flex flex-col md:flex-row justify-center items-center`}
        >
          <div
            className={`flex md:hidden h-80 w-[80%] md:w-[48%] bg-slate-200 rounded-xl shadow-md drop-shadow-lg`}
          ></div>
          <div
            className={`h-full w-[80%] md:w-[52%] md:mr-8 flex flex-col justify-start items-center md:items-end`}
          >
            <div className="font-runegifter text-3xl md:text-4xl text-yellow-710 brightness-110 mt-6 mb-2 md:mb-6 ">
              Pellentesque
            </div>
            <div className="font-lufga300 text-base md:text-lg leading-7 text-center md:text-right">
              Duis eu lacinia metus. Aenean pulvinar libero sit amet magna
              interdum ultricies. Nunc mauris ex, tristique id sem eget,
              eleifend pulvinar dolor. Donec at libero metus. Suspendisse nisl
              libero, tempus nec ipsum et, scelerisque fringilla urna. Sed
              hendrerit diam odio, vel condimentum turpis viverra convallis.
            </div>
          </div>
          <div
            className={`hidden md:flex h-80 w-[80%] md:w-[48%] bg-slate-200 rounded-xl shadow-md drop-shadow-lg`}
          ></div>
        </div>
      </div>
      <div className="w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl md:mt-16 mb-12" />
    </div>
  );
};

export default Info02;
