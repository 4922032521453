import React from "react";
import { useInView } from "react-intersection-observer";

const LandingTextTop = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center pb-8 md:pb-16`}
    >
      <div className="w-full flex flex-col md:flex-row justify-center items-center px-3 md:px-20">
        <div
          ref={ref}
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "opacity-100 -translate-x-0"
              : "opacity-0 -translate-x-[100px]"
          } w-full md:w-[60%] text-yellow-710 brightness-110 flex flex-col justify-center items-start font-runegifter text-[2.6rem]`}
        >
          <div>Artisan Coffee</div>
          <div>Roastery & Studio</div>
        </div>
        <div
          ref={ref}
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "opacity-100 translate-x-0"
              : "opacity-0 translate-x-[100px]"
          } w-full md:w-[40%] flex flex-col justify-center items-start font-lufga300`}
        >
          <div className="w-[200px] h-[3px] bg-stone-300 mb-4 mt-2 md:mt-0"></div>
          <div className="text-justify md:text-left ">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. When an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingTextTop;
