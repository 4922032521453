import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoPrimitiveDot } from "@react-icons/all-files/go/GoPrimitiveDot";
import { BiCoffee } from "@react-icons/all-files/bi/BiCoffee";
import { BiCookie } from "@react-icons/all-files/bi/BiCookie";
import { useInView } from "react-intersection-observer";
import ResponsePopUp from "./ResponsePopUp";

import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel, Pagination } from "swiper";
const smooth = "transition-all ease-in-out duration-300";
const hover = "transition-all ease-in-out duration-[500ms] ";

const query = graphql`
  {
    allContentfulMenu(sort: { fields: image___createdAt }) {
      nodes {
        id
        title
        price
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
    allContentfulBakery(sort: { fields: image___createdAt }) {
      nodes {
        id
        title
        price
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const LandingCarousel = () => {
  const {
    allContentfulMenu: { nodes: posts },
    allContentfulBakery: { nodes: bakes },
  } = useStaticQuery(query);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [coffee, setCoffee] = useState(true);
  return (
    <div
      ref={ref}
      className={` relative z-20 w-full h-full bg-white pb-10 flex flex-col justify-center items-center shadow-xl shadow-stone-900/6 xl:rounded-b-md `}
    >
      {/* title */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } flex justify-center items-center text-yellow-710 brightness-105`}
      >
        <GoPrimitiveDot className="mx-2 md:mx-4 text-xl md:text-2xl" />
        <div className="font-runegifter text-3xl sm:text-5xl">
          This Month Special
        </div>
        <GoPrimitiveDot className="mx-2 md:mx-4 text-xl md:text-2xl" />
      </div>
      {/* filter */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } relative mt-6 mb-6 px-1 py-1 rounded-full bg-stone-200 flex justify-center items-center space-x-3.5`}
      >
        <button
          onClick={() => setCoffee((prevOpen) => (prevOpen = true))}
          className={`${smooth} ${
            coffee ? " text-white bg-stone-400/0" : "bg-stone-400/50 text-black"
          } z-10 relative p-3 rounded-full grid place-content-center`}
        >
          <BiCoffee className="text-3xl " />
        </button>
        <button
          onClick={() => setCoffee((prevOpen) => (prevOpen = false))}
          className={`${smooth} ${
            coffee === false
              ? " text-white bg-stone-400/0"
              : "bg-stone-400/50 text-black"
          } z-10 relative p-3 rounded-full grid place-content-center`}
        >
          <BiCookie className="text-3xl" />
        </button>
        <div
          className={`${smooth} ${
            coffee ? " -left-2.5" : "left-[3.6rem]"
          } duration-400 absolute z-0 w-[3.4rem] h-[3.4rem] bg-blue-710 rounded-full `}
        />
      </div>
      {/* desktop */}
      <div
        className={`w-full h-full transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-x-0" : "opacity-0 translate-x-[100px]"
        } customized-swiper swiper-menu bg-white z-10 md:flex hidden`}
      >
        {coffee ? (
          <Swiper
            mousewheel={true}
            spaceBetween={20}
            breakpoints={{
              300: {
                slidesPerView: 4,
                slidesPerGroup: 2,
              },
            }}
            pagination={{
              type: "progressbar",
            }}
            modules={[Mousewheel, Pagination]}
            className={`customized-swiper swiper-menu bg-white z-10 md:flex hidden`}
          >
            {posts.map((post) => {
              const { id, title, image, price } = post;
              const pathToImage = getImage(image);

              return (
                <SwiperSlide key={id} className="relative group">
                  <div
                    className={`${hover} w-full h-full relative group-hover:-scale-x-[1]`}
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${hover} duration-[300ms] z-20 bg-white mt-10 relative group-hover:invisible group-hover:opacity-0 image-swiper h-[380px] rounded-lg shadow-md shadow-stone-900/20 my-20`}
                    />

                    <div
                      className={`${hover} group-hover:shadow-lg group-hover:shadow-stone-900/30 -scale-x-[1] z-10 absolute h-[380px] w-full rounded-lg top-[40px] font-lufga300 text-lg cursor-default bg-gradient-to-t from-gray-100 to-white flex flex-col justify-center items-center`}
                    >
                      <div className="flex flex-col justify-center items-start my-4">
                        <div className="font-lufga300 mt-1 text-[0.9rem]">
                          Small: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Medium: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Large: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                      </div>

                      <ResponsePopUp
                        title={"See more"}
                        titleIns={title}
                        image={image}
                      />
                    </div>
                  </div>

                  <div
                    className={`${smooth} grid place-content-center duration-150 w-full py-2 group-hover:invisible group-hover:opacity-0 z-20 absolute bottom-[80px] rounded-b-lg font-lufga500 text-lg cursor-default  mix-blend-screen text-black bg-white`}
                  >
                    {title}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <Swiper
            mousewheel={true}
            spaceBetween={20}
            breakpoints={{
              300: {
                slidesPerView: 4,
                slidesPerGroup: 2,
              },
            }}
            pagination={{
              type: "progressbar",
            }}
            modules={[Mousewheel, Pagination]}
            className="customized-swiper swiper-menu bg-white z-10 md:flex hidden"
          >
            {bakes.map((post) => {
              const { id, title, image, price } = post;
              const pathToImage = getImage(image);

              return (
                <SwiperSlide key={id} className="relative group">
                  <div
                    className={`${hover} w-full h-full relative group-hover:-scale-x-[1]`}
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${hover} duration-[300ms] z-20 bg-white mt-10 relative group-hover:invisible group-hover:opacity-0 image-swiper h-[380px] rounded-lg shadow-md shadow-stone-900/20 my-20`}
                    />
                    <div
                      className={`${hover} group-hover:shadow-lg group-hover:shadow-stone-900/30 -scale-x-[1] z-10 absolute h-[380px] w-full rounded-lg top-[40px] font-lufga300 text-lg cursor-default bg-gradient-to-t from-gray-100 to-white flex flex-col justify-center items-center`}
                    >
                      <div className="flex flex-col justify-center items-start my-4">
                        <div className="font-lufga300 mt-1 text-[0.9rem]">
                          Small: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Medium: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Large: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                      </div>

                      <ResponsePopUp
                        title={"See more"}
                        titleIns={title}
                        // titleInsEng={`K ${Intl.NumberFormat("en-US").format(
                        //   price
                        // )}`}
                        image={image}
                      />
                    </div>
                  </div>

                  <div
                    className={`${smooth} grid place-content-center w-full py-2 group-hover:invisible group-hover:opacity-0 z-20 absolute bottom-[80px] rounded-b-lg font-lufga500 text-lg cursor-default  mix-blend-screen text-black bg-white`}
                  >
                    {title}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>

      {/* mobile */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } w-full mt-4 flex md:hidden`}
      >
        {coffee ? (
          <div className="flex flex-wrap justify-center items-center w-full ">
            {posts.map((post) => {
              const { id, title, image, price } = post;
              const pathToImage = getImage(image);

              return (
                <div
                  key={id}
                  className={`${smooth} group relative w-[210px] h-[350px] mx-6 mb-10 rounded-xl shadow-md hover:shadow-xl shadow-stone-800/10 drop-shadow-md flex flex-col justify-center items-center`}
                >
                  <div
                    className={`${smooth} w-full h-full rounded-xl group-hover:-scale-x-[1]`}
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${smooth} duration-[300ms] group-hover:invisible group-hover:opacity-0 z-20 w-full h-full rounded-xl brightness-[95%] group-hover:brightness-100`}
                    />
                    <div
                      className={`${smooth} z-10 group-hover:shadow-lg drop-shadow-lg shadow-stone-900/30 -scale-x-[1] absolute h-full w-full rounded-xl top-0 font-lufga300 text-lg cursor-default bg-gradient-to-t from-gray-100 to-white flex flex-col justify-center items-center`}
                    >
                      <div className="flex flex-col justify-center items-start my-4">
                        <div className="font-lufga300 mt-1 text-[0.9rem]">
                          Small: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Medium: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Large: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                      </div>

                      <ResponsePopUp
                        title={"See more"}
                        titleIns={title}
                        image={image}
                      />
                    </div>
                  </div>
                  <div
                    className={`${smooth} grid place-content-center w-full py-2 group-hover:invisible group-hover:opacity-0 z-20 absolute bottom-0 rounded-b-xl font-lufga500 text-lg cursor-default  mix-blend-screen text-black bg-white`}
                  >
                    {title}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-wrap justify-center items-center w-full">
            {bakes.map((post) => {
              const { id, title, image, price } = post;
              const pathToImage = getImage(image);

              return (
                <div
                  key={id}
                  className={`${smooth} group relative w-[210px] h-[350px] mx-6 mb-10 rounded-xl shadow-md hover:shadow-xl shadow-stone-800/10 drop-shadow-md flex flex-col justify-center items-center`}
                >
                  <div
                    className={`${smooth} w-full h-full rounded-xl group-hover:-scale-x-[1]`}
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${smooth} duration-[300ms] group-hover:invisible group-hover:opacity-0 z-20 w-full h-full rounded-xl brightness-[95%] group-hover:brightness-100`}
                    />
                    <div
                      className={`${smooth} z-10 group-hover:shadow-lg drop-shadow-lg shadow-stone-900/30 -scale-x-[1] absolute h-full w-full rounded-xl top-0 font-lufga300 text-lg cursor-default bg-gradient-to-t from-gray-100 to-white flex flex-col justify-center items-center`}
                    >
                      <div className="flex flex-col justify-center items-start my-4">
                        <div className="font-lufga300 mt-1 text-[0.9rem]">
                          Small: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Medium: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                        <div className="font-lufga300 text-[0.9rem]">
                          Large: K {Intl.NumberFormat("en-US").format(price)}
                        </div>
                      </div>

                      <ResponsePopUp
                        title={"See more"}
                        titleIns={title}
                        image={image}
                      />
                    </div>
                  </div>
                  <div
                    className={`${smooth} grid place-content-center w-full py-2 group-hover:invisible group-hover:opacity-0 z-20 absolute bottom-0 rounded-b-xl font-lufga500 text-lg cursor-default  mix-blend-screen text-black bg-white`}
                  >
                    {title}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingCarousel;
