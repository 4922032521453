import React from "react";
import { useInView } from "react-intersection-observer";

const Info01 = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`bg-white flex flex-col justify-start items-center w-full shadow-xl shadow-stone-900/10 relative z-10 md:pb-12`}
    >
      <div className="w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl" />
      <div
        className={` md:w-[96%] lg:w-4/5 mt-12 md:mt-16 flex flex-col md:flex-row justify-center items-center md:justify-around md:items-start font-lufga300`}
      >
        <div
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "translate-y-0 opacity-100"
              : "translate-y-[200px] opacity-0"
          } md:w-1/3 flex flex-col justify-center items-center md:items-start mb-8 md:mb-0`}
        >
          <div className="w-[17rem] md:w-[15rem] lg:w-[16rem] aspect-square bg-slate-200 rounded-xl shadow-lg shadow-black/20 drop-shadow"></div>
          <div className="font-lufga500 text-xl italic text-yellow-710 brightness-105 mt-6 md:mt-8 mb-2 md:mb-4">
            How do we serve our coffee
          </div>
          <div className="w-60 text-center">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters
          </div>
        </div>
        <div
          className={`transition-all ease-in-out duration-[1400ms] ${
            inView
              ? "translate-y-0 opacity-100"
              : "translate-y-[300px] opacity-0"
          } md:w-1/3 flex flex-col justify-center items-center mb-8 md:mb-0`}
        >
          <div className="w-[17rem] md:w-[15rem] lg:w-[16rem] aspect-square bg-slate-200 rounded-xl shadow-lg shadow-black/20 drop-shadow"></div>
          <div className="font-lufga500 text-xl italic text-yellow-710 brightness-105 mt-6 md:mt-8 mb-2 md:mb-4">
            Our sophisticated beans
          </div>
          <div className="w-60 text-center">
            Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text, and a search for 'lorem ipsum'
          </div>
        </div>
        <div
          className={`transition-all ease-in-out duration-[1600ms] ${
            inView
              ? "translate-y-0 opacity-100"
              : "translate-y-[400px] opacity-0"
          } md:w-1/3 flex flex-col justify-center items-center md:items-end`}
        >
          <div className="w-[17rem] md:w-[15rem] lg:w-[16rem] aspect-square bg-slate-200 rounded-xl shadow-lg shadow-black/20 drop-shadow"></div>
          <div className="font-lufga500 text-xl italic text-yellow-710 brightness-105 mt-6 md:mt-8 mb-2 md:mb-4">
            Baked with heart at home
          </div>
          <div className="w-60 text-center">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia
          </div>
        </div>
      </div>
      <div className="w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl my-10" />
    </div>
  );
};

export default Info01;
